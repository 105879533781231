import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import logo from '../../images/logo.jpeg'
import axios from 'axios';

export default function NavbarTop({ userData, setUserData }) {
    let navigate = useNavigate()
    let token = localStorage.getItem('userToken')
    let headers = {
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
    }
    async function logOut() {
        let { data } = await axios(`${process.env.REACT_APP_API}/auth/logout`, { headers })
        localStorage.clear()
        setUserData(null)
    }

    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const navbarRef = useRef(null);

    const handleToggle = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setIsNavbarOpen(false);
        }
    };

    useEffect(() => {
        if (isNavbarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNavbarOpen]);

    return <>

        <nav className="navbar navbar-expand-xl navbar-light bg-light border border-1 " ref={navbarRef}>
            < div className="container-lg" >
                <a className="navbar-brand d-flex align-items-center" href="/">
                    <div className="d-flex align-items-center">
                        <img src={logo} alt="logo" width="80" className="d-inline-block me-2" />
                    </div>
                </a>

                <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isNavbarOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                    onClick={handleToggle}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">

                    <ul className="navbar-nav ms-auto mb-lg-0 text-white text-uppercase align-items-center">
                        <li className="nav-item">
                            <Link className='nav-link  text-black-50 linksRes py-2' aria-current="page" to={'/'}>Users</Link>
                        </li>
                        
                        <li className="nav-item">
                            <Link className="nav-link text-black-50 linksRes py-2" aria-current="page" to='categories'>Categories</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-black-50 linksRes py-2" aria-current="page" to='items'>Items</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-black-50 linksRes py-2" aria-current="page" to='createUser'>createUser</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-black-50 linksRes py-2" aria-current="page" to='profile'>Profile</Link>
                        </li>
                        <li className="nav-item">
                            <Link   onClick={logOut} className="nav-link text-black-50 " aria-current="page" >Logout</Link>
                        </li>
                        
                    </ul>
                </div>
            </div >
        </nav >

    </>


    // return <>
    //     <nav className="navbar navbar-expand-xl navbar-light bg-light border border-1  ">
    //         <div className="container-fluid px-5">
    //             <a className="navbar-brand d-flex align-items-center" href="/">
    //                 <div className="d-flex align-items-center">
    //                     <img src={logo} alt="logo" width="60" className="d-inline-block me-2" />
    //                     <span className="navbarLogoSize text-white text-capitalize fw-bolder">NoOoH</span>
    //                 </div>
    //             </a>
    //             {/* <a className="navbar-brand ">
    //                 <img src={logo} className='img-fluid col-3  border border-1 rounded-2  shadow-lg ' alt="" srcset="" />
    //             </a> */}
    //             <button
    //                 className="navbar-toggler d-xl-none"
    //                 type="button"
    //                 data-bs-toggle="collapse"
    //                 data-bs-target="#collapsibleNavId"
    //                 aria-controls="collapsibleNavId"
    //                 aria-expanded="false"
    //                 aria-label="Toggle navigation"
    //             >
    //                 <span className="navbar-toggler-icon"></span>
    //             </button>
    //             <div className="collapse navbar-collapse" id="collapsibleNavId">


    //                 <ul className="navbar-nav ms-auto mt-2 mt-lg-0">


    //                     {userData != null ? <li className="nav-item">
    //                         <Link onClick={logOut} className="nav-link text-center " to={'/'}>Logout</Link>
    //                         <Link className='text-decoration-none linksRes text-black-50 pt-2' to={'/'}><p>Users</p></Link>
    //                         <Link className='text-decoration-none linksRes text-black-50' to={'categories'}><p>Categories</p></Link>
    //                         <Link className='text-decoration-none linksRes text-black-50' to={'items'}><p>Items</p></Link>
    //                         <Link className='text-decoration-none linksRes text-black-50' to={'createUser'}><p>createUser</p></Link>
    //                         <Link className='text-decoration-none linksRes text-black-50' to={'profile'}><p>profile</p></Link>

    //                     </li> : null}








    //                 </ul>




    //             </div>
    //         </div>
    //     </nav>

    // </>
}
